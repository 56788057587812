import * as React from "react"
import debounce from "lodash.debounce"
import { StoreContext } from "../../context/store"

import { GatsbyImage } from "gatsby-plugin-image"
import { getShopifyImage } from "gatsby-source-shopify"
import { formatPrice } from "../../utils/formatters"
import { TrashIcon } from "@heroicons/react/outline"
import { QuantityInput } from "./quantity-input"
import { PlusIcon, MinusIcon } from "@heroicons/react/solid"

export function LineItem({ item }) {
  const { removeLineItem, checkout, updateLineItem, loading } =
    React.useContext(StoreContext)
  const [quantity, setQuantity] = React.useState(item.quantity)
  console.log(item)

  const variantImage = {
    ...item.variant.image,
    originalSrc: item.variant.image?.src,
  }

  const price = formatPrice(
    item.variant.priceV2.currencyCode,
    Number(item.variant.priceV2.amount)
  )

  const subtotal = formatPrice(
    item.variant.priceV2.currencyCode,
    Number(item.variant.priceV2.amount) * quantity
  )

  const handleRemove = () => {
    removeLineItem(checkout.id, item.id)
  }

  const uli = debounce(
    value => updateLineItem(checkout.id, item.id, value),
    300
  )

  const debouncedUli = React.useCallback(value => uli(value), [])

  const handleQuantityChange = value => {
    if (value !== "" && Number(value) < 1) {
      return
    }
    setQuantity(value)
    if (Number(value) >= 1) {
      debouncedUli(value)
    }
  }

  function doIncrement() {
    handleQuantityChange(Number(quantity || 0) + 1)
  }

  function doDecrement() {
    handleQuantityChange(Number(quantity || 0) - 1)
  }

  const image = React.useMemo(
    () =>
      getShopifyImage({
        image: variantImage,
        layout: "constrained",
        crop: "contain",
        width: 160,
        height: 160,
      }),
    [variantImage.src]
  )

  return (
    <>
      {/* <tr>
        <td>
          {image && (
            <GatsbyImage
              key={variantImage.src}
              image={image}
              alt={variantImage.altText ?? item.variant.title}
            />
          )}
        </td>
        <td>
          <h2>{item.title}</h2>
          <div>
            {item.variant.title === "Default Title" ? "" : item.variant.title}
          </div>
          <div>
            <button onClick={handleRemove} className="flex flex-row space-x-2 ">
              <TrashIcon className="w-6 h-6" /> Remove
            </button>
          </div>
        </td>
        <td>{price}</td>
        <td>
          <QuantityInput
            disabled={loading}
            value={quantity}
            aria-label="Quantity"
            onIncrement={doIncrement}
            onDecrement={doDecrement}
            onChange={e => handleQuantityChange(e.currentTarget.value)}
          />
        </td>
        <td>{subtotal}</td>
      </tr> */}

      <section>
        <div className=" ">
          {/* <div className="flex gap-3">
            <div className="grid grid-cols-4 gap-y-8 gap-x-36  pt-4 mt-4">
              <div className="col-span-2">
                <div className="uppercase text-mint">product</div>
              </div>
              <div className="uppercase text-mint">quantity</div>
              <div className="uppercase text-mint">Total</div>
              <div className="col-span-2">
                <div className="text-chalk">{item.title}</div>
              </div>

              <div className="flex flex-wrap mb-4">
                <div className="flex gap-4 border border-mint items-center text-mint">
                  <button
                    className="p-4 cursor-pointer hover:bg-mint/10 duration-200"
                    onClick={doDecrement}
                  >
                    <MinusIcon className="w-3" />
                  </button>
                  <div className="select-none font-mono">{quantity}</div>
                  <button
                    className="p-4 cursor-pointer hover:bg-mint/10 duration-200"
                    onClick={doIncrement}
                  >
                    <PlusIcon className="w-3" />
                  </button>
                </div>
              </div>

              <div className="text-mint">{subtotal}</div>
            </div>
          </div> */}
          <div className="flex justify-between items-center py-5 ">
            <div className="flex  gap-5 items-center">
              {image && (
                <GatsbyImage
                  key={variantImage.src}
                  image={image}
                  alt={variantImage.altText ?? item.variant.title}
                />
              )}
              <div>
                <div className="uppercase text-mint lg:block hidden">
                  {item.title}
                </div>
                <div className=" w-full lg:block hidden">
                  <div>
                    <button
                      onClick={handleRemove}
                      className="flex flex-row space-x-2 items-center mt-5  "
                    >
                      <TrashIcon className="w-6 h-6 text-red-500 " />
                    </button>
                  </div>
                </div>
              </div>
            </div>

            <div className="flex lg:gap-40 gap-0 items-center lg:flex-row flex-col">
              <div className="flex flex-wrap mb-4">
                <div className="flex md:gap-4 gap-2 border border-mint items-center text-mint">
                  <button
                    className="md:p-4 p-2 cursor-pointer hover:bg-mint/10 duration-200"
                    onClick={doDecrement}
                  >
                    <MinusIcon className="w-3" />
                  </button>
                  <div className="select-none font-mono">{quantity}</div>
                  <button
                    className="md:p-4 p-2 cursor-pointer hover:bg-mint/10 duration-200"
                    onClick={doIncrement}
                  >
                    <PlusIcon className="w-3" />
                  </button>
                </div>
              </div>

              <div className="uppercase text-mint">{subtotal}</div>
              <div className=" w-full lg:hidden block">
                <div>
                  <button
                    onClick={handleRemove}
                    className="flex flex-row space-x-2 items-center mt-5  "
                  >
                    <TrashIcon className="w-6 h-6 text-red-500 " />
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  )
}

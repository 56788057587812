import * as React from "react"
import { Link } from "gatsby"
import { StoreContext } from "../context/store"
import { formatPrice } from "../utils/formatters"
import { LineItem } from "../components/cart/line-item"
import { StaticImage } from "gatsby-plugin-image"

const Cart = () => {
  const { checkout, loading } = React.useContext(StoreContext)
  const emptyCart = checkout.lineItems.length === 0

  const handleCheckout = () => {
    window.open(checkout.webUrl)
  }

  return (
    <div>
      {emptyCart ? (
        <div className="min-h-screen bg-dusk flex justify-center items-center">
          <div className="">
            <h1 className="text-center text-salmon text-3xl mb-4">
              Your cart is empty
            </h1>
            <p className="text-center text-xl text-mint mb-6">
              Looks like you haven’t found anything yet. We understand that
              sometimes it’s hard to choose — maybe this helps:
            </p>
            <div className="text-lg font-firs-semibold text-mint">
              <Link to="/">Go back</Link>
            </div>
          </div>
        </div>
      ) : (
        <>
          {/* <h1>Your cart</h1>
          <table>
            <thead>
              <tr>
                <th>Image</th>
                <th>Product</th>
                <th>Price</th>
                <th>Qty.</th>
                <th>Total</th>
              </tr>
            </thead>
            <tbody>
              

              <tr>
                <td></td>
                <td></td>
                <td></td>
                <td>Subtotal</td>
                <td>
                  {formatPrice(
                    checkout.subtotalPriceV2.currencyCode,
                    checkout.subtotalPriceV2.amount
                  )}
                </td>
              </tr>
              <tr>
                <td></td>
                <td></td>
                <td></td>
                <td>Taxes</td>
                <td>
                  {formatPrice(
                    checkout.totalTaxV2.currencyCode,
                    checkout.totalTaxV2.amount
                  )}
                </td>
              </tr>
              <tr>
                <td></td>
                <td></td>
                <td></td>
                <td>Shipping</td>
                <td>Calculated at checkout</td>
              </tr>
              <tr>
                <td></td>
                <td></td>
                <td></td>
                <td>Total Price</td>
                <td>
                  {formatPrice(
                    checkout.totalPriceV2.currencyCode,
                    checkout.totalPriceV2.amount
                  )}
                </td>
              </tr>
            </tbody>
          </table>
          <button
            onClick={handleCheckout}
            disabled={loading}
            className="bg-green-400 p-2"
          >
            Checkout
          </button> */}

          <div className="  py-8  bg-dusk  ">
            <div className=" flex justify-between items-center  container mx-auto  ">
              <div className="lg:block hidden">
                <Link to="/">
                  <StaticImage
                    src="../images/brand/logo_light.png"
                    placeholder="blurred"
                    width={150}
                    quality={100}
                    alt="Softmatter"
                    className="cursor-pointer"
                  />
                </Link>
              </div>

              <div className="flex lg:gap-8 gap-4 md:text-base text-sm text-chalk">
                <Link to="/">
                  <div className="cursor-pointer font-firs-semibold">
                    Softmatter Main Site
                  </div>
                </Link>
                <Link to="/toolkit/#explore">
                  <div className="cursor-pointer font-firs-semibold">
                    Development Kits
                  </div>
                </Link>
                <Link to="/about">
                  <div className="cursor-pointer font-firs-semibold">
                    About Us
                  </div>
                </Link>
                <Link to="/contact">
                  <div className="cursor-pointer font-firs-semibold">
                    Contact
                  </div>
                </Link>
              </div>
            </div>
            <div className="container mx-auto md:py-24 py-10 min-h-screen">
              <div className="border md:border-mint border-transparent p-8">
                <div className="lg:block hidden">
                  <div className="flex justify-between items-center ">
                    <div className="uppercase text-mint">product</div>
                    <div className="flex lg:gap-40 gap-10">
                      <div className="uppercase text-mint">quantity</div>
                      <div className="uppercase text-mint">Total</div>
                      {/* <div className="uppercase text-mint">Action</div> */}
                    </div>
                  </div>
                </div>
                {checkout.lineItems.map(item => (
                  <LineItem item={item} key={item.id} />
                ))}
              </div>
              <div>
                <div className="w-full flex justify-end pt-5">
                  <div className="lg:w-1/3 w-full">
                    <div className="grid grid-cols-2   ">
                      <div className="text-mint">Sub Total</div>
                      <div className="text-chalk flex justify-end">
                        {" "}
                        {formatPrice(
                          checkout.subtotalPriceV2.currencyCode,
                          checkout.subtotalPriceV2.amount
                        )}
                      </div>
                    </div>
                    <div className="grid grid-cols-2 ">
                      <div className="text-mint">Taxes</div>
                      <div className="text-chalk flex justify-end">
                        {formatPrice(
                          checkout.totalTaxV2.currencyCode,
                          checkout.totalTaxV2.amount
                        )}
                      </div>
                    </div>
                    <div className="grid grid-cols-2 ">
                      <div className="text-mint">Shipping</div>
                      <div className="text-chalk flex justify-end md:text-base text-sm">
                        Calculated at checkout
                      </div>
                    </div>
                    <div className="grid grid-cols-2 ">
                      <div className="text-mint">Total Price</div>
                      <div className="text-chalk flex justify-end">
                        {formatPrice(
                          checkout.totalPriceV2.currencyCode,
                          checkout.totalPriceV2.amount
                        )}
                      </div>
                    </div>
                  </div>
                </div>
                <div className="flex justify-end pb-6">
                  <button
                    onClick={handleCheckout}
                    disabled={loading}
                    className="bg-mint px-4 py-2 mt-6"
                  >
                    Checkout
                  </button>
                </div>
              </div>
              {/* <div>
                <div className="border border-mint">
                  {checkout.lineItems.map(item => (
                    <LineItem item={item} key={item.id} />
                  ))}
                </div>
                <div className="text-mint flex mt-4">
                  Customers are responsible for additional customs fees, duties,
                  and tax
                </div>
              </div> */}
            </div>
          </div>
        </>
      )}
    </div>
  )
}

export default Cart
